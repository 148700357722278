import { gql } from '~/apollo/gql'
import { defineStore } from 'pinia'
import { useFragment } from '~/apollo/fragment-masking'
import type { User as UserType } from 'oidc-client-ts'

export const useSession = defineStore('session', () => {
  const { $um } = useNuxtApp()

  const impersonating = ref<String | null>(window.localStorage.getItem('sato48:impersonate'))

  const sso = ref<UserType | null>(null)
  $um.getUser().then((user) => {
    sso.value = user
  })
  const roles = computed<string[]>(() => sso.value?.profile.roles as string[] || [])

  const { result, loading } = useQuery(Whoami, {}, () => ({
    enabled: sso.value != null
  }))
  const user = computed(() => useFragment(User, result.value?.users[0]))
  const isAuthenticated = computed(() => !!user.value)

  const impersonate = async (id: number) => {
    if (id) {
      window.localStorage.setItem('sato48:impersonate', id.toString())
      $um.signinRedirect({
        scope: `openid offline email impersonate.${id}`
      })
    } else {
      window.localStorage.clear()
      $um.signinRedirect({
        scope: `openid offline email`
      })
    }
  }

  return { loading, sso, user, roles, isAuthenticated, impersonate, impersonating }
})

const User = gql(`fragment User on users {
  id name email sub picture
}`)

const Whoami = gql(`query Whoami {
  users {
    ...User
  }
}`)

